import React from "react";
import Card from "../../../components/Cards";
import Checkpoints from "./TabComponents/Checkpoints";
import Events from "./TabComponents/Events";
import Logs from "./TabComponents/Logs";

const Tab = ({ openTab, setOpenTab, driverFreight }) => {

    return (
        <div className="overflow-auto p-4 -mt-4">
            <Card className="h-full ">
                <div className="flex border-b border-C3 py-2">
                    <button
                        className={`font-normal mr-12 cursor-pointer  "text-roxo_oficial font-bold"
                            }`}
                        onClick={() => setOpenTab("Eventos")}
                    >
                        Eventos
                    </button>
                    <button
                        className={`font-normal mr-12 cursor-pointer ${openTab === "Logs" ? "text-roxo_oficial font-bold" : ""
                            }`}
                        onClick={() => setOpenTab("Logs")}
                    >
                        Logs
                    </button>
                    <button
                        className={`font-normal mr-12 cursor-pointer ${openTab === "Chekpoints" ? "text-roxo_oficial font-bold" : ""
                            }`}
                        onClick={() => setOpenTab("Chekpoints")}
                    >
                        Chekpoints
                    </button>
                </div>
                {openTab === "Eventos" && <Events driverFreight={driverFreight} />}
                {openTab === "Logs" && <Logs driverFreight={driverFreight} />}
                {openTab === "Chekpoints" && <Checkpoints driverFreight={driverFreight} />}
            </Card>
        </div>
    );
};

export default Tab;
